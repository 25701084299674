import React from 'react';
import ReactClass from 'create-react-class';
import {Button, FormGroup, FormControl, ControlLabel, Modal, Form, Alert, Label, Checkbox, Col} from 'react-bootstrap';
import * as UserAction from "./actions/users";
import {connect} from "react-redux";
import spinnerImage from "./resources/images/spinner.gif";

let component = ReactClass({

    getInitialState() {
        return {
            phonenumber: "",
            name: "",
            showModal: false,
            alert: "",
            loading: false,
            sendEmail: false,
        };
    },

    closeModal() {
        this.props.close();
    },

    handleAlertDismiss() {
        this.setState({alert: ""});
    },

    componentWillReceiveProps(newProps) {
        let _this = this;
        let user = newProps.user || {};
        _this.setState({
            user,
            phonenumber: user.phonenumber,
            name: user.name,
        });
    },

    save(event) {
        event.preventDefault();

        this.setState({alert: "", loading: true});
        let userInfo = this.state.user.object.get("userInfo");
        userInfo.set("phonenumber", this.state.phonenumber);
        userInfo.set("name", this.state.name);
        userInfo.save().then(() => {
            this.setState({success: true, loading: false});
            window.location.reload();
        }).catch((error) => {
            this.setState({alert: error.message, loading: false});
        });

        return false;
    },

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    },

    handleCheckboxChange(event) {
        this.setState({[event.target.name]: event.target.checked});
    },

    render() {
        return (
            <Modal show={this.props.show} onHide={this.closeModal}>
                <Form onSubmit={this.save}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit user</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.alert !== "" &&
                            <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                                <h4>Error</h4>
                                <p>{this.state.alert}</p>
                            </Alert>
                        }
                        <FormGroup
                            controlId="formBasicText"
                        >
                            <ControlLabel>Name</ControlLabel>
                            <FormControl
                                type="text"
                                name="name"
                                value={this.state.name}
                                placeholder="Full name"
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        {/*<FormGroup*/}
                        {/*    controlId="formBasicText"*/}
                        {/*>*/}
                        {/*    <ControlLabel>E-mail address</ControlLabel><br/>*/}
                        {/*    {this.props.mail.toLowerCase()} {this.props.mail !== this.props.mail.toLowerCase() &&*/}
                        {/*    <Label bsStyle="warning">Note: the e-mail address has been changed to lower case.</Label>}*/}
                        {/*</FormGroup>*/}
                        <FormGroup
                            controlId="formBasicText"
                        >
                            <ControlLabel>Phone number</ControlLabel>
                            <FormControl
                                type="text"
                                name="phonenumber"
                                value={this.state.phonenumber}
                                placeholder=""
                                onChange={this.handleChange}
                            />
                        </FormGroup>

                    </Modal.Body>
                    <Modal.Footer>
                        {/*<Button bsStyle="danger" style={{float: "left"}}>Delete user</Button>*/}

                        <Button onClick={this.closeModal}>
                            {this.state.success ? "Close" : "Cancel"}
                        </Button>
                        {!this.state.success && (this.state.loading ? (
                            <img src={spinnerImage} alt="Loading..."/>
                        ) : (
                            <Button bsStyle="primary" type="submit">Save</Button>
                        ))}
                    </Modal.Footer>
                </Form>
            </Modal>

        );
    },

});

export default connect(state => ({}
), dispatch => ({
    cancelAddUserToProject: () => dispatch(UserAction.cancelAddUserToProject()),
    addUser: (mail, name, phone, project, sendEmail) => dispatch(UserAction.addUser(mail, name, phone, project, sendEmail)),
}))(component);