import React from 'react';
import moment from "moment";
import RegistrationMapOverlayBuilder from "./components/RegistrationMapOverlayBuilder";
import FullImageGallery from "./FullImageGallery";
import {Button} from "react-bootstrap";
import TypeSelector from "./components/TypeSelector";
import PrioritySelector from "./components/PrioritySelector";
import CallbackSelector from "./components/CallbackSelector";
import {connect} from 'react-redux';
import * as RegistrationAction from './actions/registrations'
import DescriptionField from "./components/DescriptionField";
import VersionLogOverview from "./components/VersionLogOverview";
import ReactionField from "./components/ReactionField";
import ModalReaction from "./components/ModalReaction";
import unknownLocationImage from "./resources/images/unknown-location.png";
import RegistrationOpenSelector from "./components/RegistrationOpenSelector";
import ActivitySelector from "./components/ActivitySelector";
import DangerSelector from "./components/DangerSelector";
import WindparkSelector from "./components/WindparkSelector";
import WorkpackageSelector from "./components/WorkpackageSelector";
import AddImage from "./components/AddImage";
import TurbineSelector from "./components/TurbineSelector";
import Parse from "parse";
import spinnerImage from "./resources/images/spinner.gif";

class RegistrationPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: !props.registration.id,
            editingId: props.registration.id,
            showReactionModal: false,
            loading: false,
            loadingRegeneratedReport: false,
            bypassRole: true,
        };
        this.setEditing = this.setEditing.bind(this);
        this.savePDF = this.savePDF.bind(this);
        this.openReactionModal = this.openReactionModal.bind(this);
        this.closeReactionModal = this.closeReactionModal.bind(this);
        this.save = this.save.bind(this);
    }

    setEditing(editing) {
        this.setState({editing})
    }

    openReactionModal() {
        this.setState({showReactionModal: true})
    }

    closeReactionModal(reaction) {
        if (reaction !== undefined) {
            this.props.registration.set("reaction", reaction);
            this.props.registration.save()
                .then(() => this.setState({showReactionModal: false}))
        } else {
            this.setState({showReactionModal: false});
        }
    }

    cancel() {
        window.location.reload();
    }

    async save() {
        let {registration, workpackageRequired} = this.props;
        console.log("Saving registration...", registration);

        if (!registration.get("registrationPriority")) {
            alert("Select a priority");
            return;
        }
        if (workpackageRequired && !registration.get("workpackage")) {
            alert("Select a workpackage");
            return;
        }

        this.setState({loading: true});

        let newRegistration = false;
        if (!registration.id) {
            newRegistration = true;
        }

        let relation = registration.relation("images");
        let first = true;
        for (let image of this.props.images[registration.id || "new"] || []) {
            if (image.id) continue;
            image.set("localUrl", null);
            await image.save().then(image => {
                relation.add(image);
            });
            if (newRegistration && first) {
                first = false;
                registration.set("thumbnailImage", image);
            }
        }

        registration.updated = true;
        this.setState({editing: false});

        console.log("Saving registration...", registration);

        registration.save().then((result) => {
            if (newRegistration) {
                window.location.href = "/project/" + this.props.project.id + "/registration/" + result.id;
            } else {
                this.props.setActiveRegistration(result.id, true);
                this.setState({loading: false});
            }
        }).catch(e => {
            console.error(e);
            alert("Something went wrong...");
        })

        // this.props.updateRegistrationObject(registration);

    }

    componentWillReceiveProps(newProps) {

        if (newProps.registration && newProps.registration.id === this.props.registration.id) return;

        this.setState({
            editing: (newProps.registration && (newProps.registration.dirty() || !newProps.registration.id)),
            editingId: newProps.registration,
        });
        let _this = this;

        const {registration} = newProps;
        if (!registration) return;
        let registrationId = registration.id;
        _this.setState({
            assignedUsers: [],
            registrationId: null,
        });
        if (!this.state.registrationId || this.state.registrationId !== registrationId) {
            registration.get("users") && registration.get("users").query().include("userInfo").find().then(assignedUsers => {
                if (!_this.state.registrationId || (_this.state.registrationId && _this.state.registrationId === registrationId)) {
                    _this.setState({
                        assignedUsers,
                        registrationId
                    })
                }
            });
        }

    }

    delete() {

        if (window.confirm("Are you sure you want to delete this report?")) {
            this.props.registration.set("deleted", true);
            this.props.registration.save()
                .then(() => window.location.href = "/project/" + this.props.project.id);
        }

    }

    async savePDF() {

        let {registration,} = this.props;
        let _this = this;
        if (this.state.editing) {
            alert("Save the registration first");
            return;
        }
        _this.setState({loadingRegeneratedReport: true});

        registration.set("generatePdf", true);
        await registration.save().catch(e => {
            console.error(e);
            alert("Something went wrong...");
        })

        let finished = false;
        let tries = 0;
        do {
            let report = await this.checkRegeneratedReportStatus();
            if (report && report.get("finished") === true) {
                console.log(report);
                finished = true;
                _this.setState({loadingRegeneratedReport: false});
                let downloadUrl = "https://parse.hseq.webzites.link/pdf/" + report.id;
                // download file
                let a = document.createElement('a');
                a.href = downloadUrl;
                // a.download = registration.getStyledTrackingNumber() + ".pdf";
                a.target = "_blank";
                a.click();
                break;
            }
            tries++;
            if (tries > 20) {
                alert("Something went wrong...");
                _this.setState({loadingRegeneratedReport: false});
                break;
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
        } while (!finished);

    }

    async checkRegeneratedReportStatus() {

        let {registration} = this.props;
        let RegeneratedReports = Parse.Object.extend("RegeneratedReports");
        let query = new Parse.Query(RegeneratedReports);
        query.equalTo("registration", registration)
        query.descending("createdAt")
        query.limit(1);
        const results = await query.find();
        return results ? results[0] : null;

    }

    render() {

        const {registration, project, map, role} = this.props;
        const {assignedUsers, bypassRole, loadingRegeneratedReport} = this.state;

        return (
            registration && <>
                <label>
                    <input type="checkbox" checked={!bypassRole}
                           onChange={() => this.setState({bypassRole: !bypassRole})}/>
                    Use new role based permissions
                </label>
                <div className="registration-container">
                    <div className="toolbar">
                        <div className="title">
                            <strong>{!registration.getStyledTrackingNumber() && "New "}Report</strong> {registration.getStyledTrackingNumber()}
                        </div>
                        {this.state.editing ? (
                            <div className="pull-right">
                                <Button bsSize="small"
                                        onClick={() => this.cancel()}>cancel</Button>&nbsp;
                                <Button bsSize="small" bsStyle="primary"
                                        onClick={this.save}>save</Button>
                            </div>
                        ) : (
                            <div>

                                {(bypassRole || (role && role.get("create"))) &&
                                    <Button className="pull-right" bsSize="small"
                                            onClick={() => this.setEditing(true)}>edit</Button>}

                                {project.isHSEManager && !registration.get("reaction") && (
                                    <Button className="pull-right" bsSize="small" style={{marginRight: "10px"}}
                                            onClick={() => this.openReactionModal()}>add reaction</Button>
                                )}

                                {loadingRegeneratedReport ?
                                    <div className="pull-right">
                                        <img src={spinnerImage} alt="Loading..."
                                        style={{height: 24, marginRight: 10}}/>
                                    </div>
                                    :
                                    <a href={"javascript:void(0)"} className="pull-right" onClick={this.savePDF}>
                                        <img src={"/resources/images/np_download-file_598283_FFFFFF.svg"}
                                             style={{height: 24, marginRight: 10}}/>
                                    </a>
                                }
                            </div>
                        )}
                    </div>

                    <table className="registration-details-table">
                        <tbody>
                        <tr>
                            <th style={{width: "33%"}}>Number</th>
                            <th colSpan="2" style={{textAlign: "center", width: "34%"}}>Closed</th>
                            <th style={{textAlign: "right", width: "33%"}}>Reporter</th>
                        </tr>
                        <tr>
                            <td>{registration.getStyledTrackingNumber()}</td>
                            <td style={{textAlign: "center"}} colSpan="2">
                                <RegistrationOpenSelector registration={registration} editing={this.state.editing}/>
                            </td>
                            <td style={{textAlign: "right"}}>{registration.get("user") && registration.get("user").get("userInfo") && registration.get("user").get("userInfo").get("name")}</td>
                        </tr>
                        <tr>
                            <th colSpan="2">Type</th>
                            <th colSpan="2" style={{textAlign: "right"}}>Priority</th>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <TypeSelector registration={registration} editing={this.state.editing}
                                              bypassRole={bypassRole}/>
                            </td>
                            <td style={{textAlign: "right"}} colSpan="2">
                                <PrioritySelector registration={registration} editing={this.state.editing}/>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan="1">
                                Workpackage
                            </th>
                            <th style={{textAlign: "center"}} colSpan="2">
                                Windpark
                            </th>
                            <th style={{textAlign: "right"}} colSpan="1">
                                Turbine
                            </th>
                        </tr>
                        <tr>
                            <td colSpan="1">
                                <WorkpackageSelector registration={registration} editing={this.state.editing}
                                                     bypassRole={bypassRole}/>
                            </td>
                            <td colSpan="2" style={{textAlign: "center"}}>
                                <WindparkSelector registration={registration} editing={this.state.editing}
                                                  bypassRole={bypassRole}/>
                            </td>
                            <td colSpan="1" style={{textAlign: "right"}}>
                                <TurbineSelector registration={registration} editing={this.state.editing}
                                                 bypassRole={bypassRole}/>
                            </td>
                        </tr>
                        <tr>
                            {/*<th>Callback</th>*/}
                            <th colSpan="2">Date</th>
                            <th colSpan="2" style={{textAlign: "right"}}>Assigned to</th>
                        </tr>
                        <tr>
                            {/*<td>*/}
                            {/*    <CallbackSelector registration={registration} editing={this.state.editing} />*/}
                            {/*</td>*/}
                            <td colSpan="2">{moment(registration.get("createdAt")).format("dddd D MMMM YY, HH:mm")}</td>

                            <td colSpan="2" style={{textAlign: "right"}}>
                                {(!assignedUsers || assignedUsers.length === 0) && "-"}
                                {assignedUsers && assignedUsers.map((user, i) => <span key={"assU" + i}>
                                    {user.get("userInfo").get("name")}
                                </span>)}
                            </td>
                        </tr>
                        <tr>
                            <th colSpan="2">Activity</th>
                            <th colSpan="2" style={{textAlign: "right"}}>
                                Danger
                            </th>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <ActivitySelector registration={registration} editing={this.state.editing}/>
                            </td>
                            <td colSpan="2" style={{textAlign: "right"}}>
                                <DangerSelector registration={registration} editing={this.state.editing}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    {/*{registration.get("coordinates") && JSON.stringify(registration.get("coordinates"))}*/}

                    {registration.get("coordinates") ? (
                        <RegistrationMapOverlayBuilder project={project} map={map}
                                                       coordinates={registration.get("coordinates")}/>
                    ) : (
                        <div style={{backgroundColor: "#bbb", padding: "20px", textAlign: "center"}}>
                            <img src={unknownLocationImage} style={{height: "40px"}} alt="Loading..."/>
                        </div>
                    )}

                    <br style={{clear: "both"}}/>

                    <DescriptionField registration={registration} editing={this.state.editing}/>

                    <ReactionField registration={registration}
                                   editing={this.state.editing /* && project.isHSEManager */}
                                   showModal={this.state.showModal}/>

                    <ModalReaction show={this.state.showReactionModal} close={this.closeReactionModal}/>

                    <FullImageGallery registration={registration}/>

                    {this.state.editing && <AddImage registration={registration} editing={this.state.editing}/>}

                    <Button bsSize="small" bsStyle="danger" style={{marginBottom: '10px'}}
                            onClick={() => this.delete()}>Delete report</Button>

                    {registration.get("versionLogCount") > 0 && (
                        <VersionLogOverview registration={registration}/>
                    )}

                </div>
            </>
        );
    }

}

export default connect(state => ({
    workpackageRequired: state.projects.active && state.projects.items[state.projects.active]
        && state.projects.items[state.projects.active].get("workpackageRequired") || false,
    role: state.projects.active && state.projects.items[state.projects.active]
        && state.projects.items[state.projects.active].role,
    images: state.registrations.images,
}), dispatch => ({
    updateRegistrationObject: registration => dispatch(RegistrationAction.updateRegistrationObject(registration)),
    setActiveRegistration: (registration, force) => dispatch(RegistrationAction.setActiveRegistration(registration, force)),
}))(RegistrationPage);